import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';

import Photo1 from "../photos/IMG_8011.jpg";
import Photo2 from "../photos/IMG_8012.jpg";
import Photo3 from "../photos/IMG_8022.jpg";


const items = [
  {
    src: Photo1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    header: 'Header n°1',
    key: '1'
  },
  {
    src: Photo2,
    altText: 'Slide 2',
    caption: 'Slide 2',
    header: 'Header n°2',
    key: '2'
  },
  {
    src: Photo3,
    altText: 'Slide 3',
    caption: 'Slide 3',
    header: 'Header n°3',
    key: '3'
  }
];

const Example = () => <UncontrolledCarousel interval="3000" items={items} />;

export default Example;