import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function MentionLegales() {
  return (
    <div className="App">
      <div className="divHack" style={{height: "100px", display: "none"}}></div>
      <h3>Mentions legales</h3>

      <Link to="./" className="backButton">
        Retour
      </Link>
      <div className="mention1">
        <p>STI SAS</p>
        <p>
          Le site Internet STI38.fr est hébergé par la société OVH Hosting LTD 5
          Fitzwilliam Place, Dublin 2, Ireland.
        </p>
        <p>
          STI38.fr est un site est édité par la société STI située à 115 Bis
          route de la Bourbre, 38290 SATOLAS ET BONCE
        </p>
        <p>N°Siren : 35 1820 857 RCS Vienne</p>
        <p>N°TVA : FR 54 351 820 857</p>
        <p>Capital social : 6722,45€</p>
      </div>
      <div className="footer">
        <div className="contactDiv">
          <p>
            <i class="fa fa-map-marker"></i> 115 Bis route de la Bourbre, 38290
            SATOLAS ET BONCE
          </p>
          <p>
            <i class="fa fa-phone"></i> 04 74 94 11 32
          </p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.7224535356136!2d5.141503958146943!3d45.66730424378721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4cdb11ebc57ad%3A0x34ed24b987ec4c5!2s115%20Route%20de%20la%20Bourbre%2C%2038290%20Satolas-et-Bonce!5e0!3m2!1sfr!2sfr!4v1584692339835!5m2!1sfr!2sfr"
          style={{
            frameborder: "0",
            style: "border:0",
            allowfullscreen: "",
            tabindex: "0"
          }}
          className="googleMap"
        ></iframe>
      </div>
    </div>
  );
}

export default MentionLegales;
