import React, { Component } from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./homepage";
import NotFoundPage from "./NotFoundPage";
import MentionLegales from "./MentionLegales";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/mentionLegales" exact component={MentionLegales} />
        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404"/>
      </Switch>
    </Router>
  );
}

export default App;
