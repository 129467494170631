import React from "react";
import "./App.css";
import Carroussel from "./carroussel/carroussel";
import Gallery from "./gallery/gallery";
import Form from "./form/form";
import logo from "./logoSTI.png";
import logo2 from "./logo2.jpg";
import { Link } from "react-router-dom";
import photo1 from "./photo1.jpg";
import photo2 from "./photo2.jpg";
import EnergieImg from "./energie.png";
import AttestationVerte from "./AttestationVerte.pdf";
import $ from "jquery";
import Photo3 from "./photos/IMG_8013.jpg";
import Photo4 from "./photos/IMG_8014.jpg";

function Homepage() {
  $(document).ready(function() {
    $(window).scroll(function() {
      if ($(window).scrollTop() > 40) {
        $("#effect1").fadeTo(2500, 1);
        $("#effect2").fadeTo(2500, 1);
      }
    });
  });

  return (
    <div className="App">
      <img src={logo} className="logo" alt="logo sti"></img>
      <h1>Fabrication de pièces sur plan, en petites séries...</h1>

      <div className="flex700">
        <div
          className="textPresentation"
          style={{ textAlign: "start" }}
        >
          <p>
            STI est avant tout une équipe à taille humaine. Grace à ses régleurs
            expérimentés et à son organisation rodée, STI vous apporte
            compétence et souplesse pour la réalisation de vos pièces usinées
            sur plan. Son parc machine récent permet de fabriquer vos petites
            séries avec qualité et compétitivité.
          </p>
        </div>
        <img
          src={Photo3}
          style={{
            width: "35%",
            border: "1px solid black",
            opacity: "0"
          }}
          alt=" "
          id="effect1"
        />
      </div>
      <div className="flex700">
        <img
          src={Photo4}
          style={{
            width: "35%",
            marginBottom: "40px",
            border: "1px solid black",
            opacity: "0"
          }}
          id="effect2"
          alt=" "
        />

        <div
          className="textPresentation"
          style={{ textAlign: "start" }}
        >
          <p>
            STI livre vos pièces finies en s’appuyant sur son réseau de
            partenaires de proximité et réalise toutes opérations de tournage,
            fraisage, rectification, électroérosion, soudure, traitement de
            surface, traitement thermique, marquage, montage, contrôle.
          </p>

          <p>
            Ses machines à commande numérique récentes : tours 2, 3 et 4 axes et
            centre d’usinage 3 et 5 axes. Les capacités machines en tournage :
            diamètre 300 x longueur 600 et diamètre 60 x longueur 3500. En
            fraisage : 900 x 500 x 300.
          </p>
        </div>
      </div>

      <Carroussel className="carroussel" />
      <p style={{ marginTop: "150px" }}>
        Depuis 30 ans, STI façonne couramment l'acier, l'aluminium, l'inox, les
        plastiques, le bronze, le cuivre.
      </p>

      <p>
        STI travaille pour les secteurs d'activité : équipements industriels,
        métallurgie, engins de TP, véhicules industriels, chimie, plasturgie.
      </p>

      <p>
        STI est située aux portes de{" "}
        <span style={{ fontWeight: "bold" }}>Lyon</span>, à proximité des grands
        axes de transport.
      </p>

      <div className="engagement">
        
        <h2>STI s’engage pour l’environnement</h2>
        

        <p>
          • Consommation d'électricité 100% renouvelable d’origine hydraulique
          <p><img className="attestationVerte" onClick={() => window.open(AttestationVerte)} src={EnergieImg} style={{borderRadius: "5%", margin: "20px"}}></img></p>
        </p>
        <p>• Tri des déchets et recyclage : métaux, papier, carton, bois</p>
        <p>
          • Utilisation de lubrifiants sans HAP et sans BaP classés cancérogènes
          (Hydrocarbures Polycycliques Aromatiques et Benzo(a)Pyrène)
        </p>
        <p>• Clients et fournisseurs de proximité</p>

        <h2>STI s’engage pour l'avenir</h2>
        <p>• Prévention : bruit, TMS, pollution des sols</p>
        <p>• Accueil de stagiaires</p>
        <div style={{marginTop: "100px"}}>
          <p style={{ color: "white" }}>
            STI est lauréat du <a target="_blank" style={{color: "#bdffe9"}} href="https://www.reseau-entreprendre.org/rhone/">Réseau Entreprendre</a>
          </p>
          
          <a  target="_blank" href="https://www.reseau-entreprendre.org/rhone/"><img
            src={logo2}
            style={{
              margin: "auto",
              borderRadius: "5%",
              width:"10%"
            }}
            className="logo2"
            alt="logo2 sti"
          ></img></a>
        </div>
      </div>
      <Gallery />

      <Form></Form>
      <div className="conteneurFooter">
        <div className="footer">
          <div className="contactDiv">
            <p>
              <i class="fa fa-map-marker"></i> 115 Bis route de la Bourbre,
              38290 SATOLAS ET BONCE
            </p>
            <p>
              <i class="fa fa-phone"></i> 04 74 94 11 32
            </p>
            <p>
              <Link to="/mentionLegales">Mentions légales</Link>
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.7224535356136!2d5.141503958146943!3d45.66730424378721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4cdb11ebc57ad%3A0x34ed24b987ec4c5!2s115%20Route%20de%20la%20Bourbre%2C%2038290%20Satolas-et-Bonce!5e0!3m2!1sfr!2sfr!4v1584692339835!5m2!1sfr!2sfr"
            style={{
              frameborder: "0",
              style: "border:0",
              allowfullscreen: "",
              tabindex: "0"
            }}
            className="googleMap"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
