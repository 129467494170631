import React, { Component } from "react";
import DztImageGalleryComponent from "reactjs-image-gallery";
import Photo1 from "../photos/IMG_8011.jpg";
import Photo2 from "../photos/IMG_8012.jpg";
import Photo3 from "../photos/IMG_8013.jpg";
import Photo4 from "../photos/IMG_8014.jpg";
import Photo5 from "../photos/IMG_8016.jpg";
import Photo6 from "../photos/IMG_8017.jpg";
import Photo7 from "../photos/IMG_8018.jpg";
import Photo8 from "../photos/IMG_8019.jpg";
import Photo9 from "../photos/IMG_8021.jpg";
import Photo10 from "../photos/IMG_8022.jpg";
import Photo11 from "../photos/IMG_8023.jpg";
import Photo12 from "../photos/IMG_8024.jpg";
import Photo13 from "../photos/IMG_8025.jpg";
import Photo14 from "../photos/IMG_8026.jpg";
import Photo15 from "../photos/IMG_8027.jpg";
import Photo16 from "../photos/IMG_8028.jpg";
import Photo17 from "../photos/IMG_8033.jpg";
import Photo18 from "../photos/IMG_8035.jpg";
import Photo19 from "../photos/IMG_8036.jpg";
import Photo20 from "../photos/IMG_8037.jpg";
import Photo21 from "../photos/IMG_8038.jpg";
import Photo22 from "../photos/IMG_8039.jpg";
import Photo23 from "../photos/IMG_8041.jpg";
import Photo24 from "../photos/IMG_8042.jpg";
import Photo25 from "../photos/IMG_8044.jpg";
import Photo26 from "../photos/IMG_8045.jpg";
import Photo27 from "../photos/IMG_8046.jpg";
import Photo28 from "../photos/IMG_8047.jpg";

class Gallery extends Component {
  render() {
    var data = [
      {
        url: Photo1,
        thumbUrl: Photo1,
      },
      {
        url: Photo2,
        thumbUrl: Photo2,
      },
      {
        url: Photo3,
        thumbUrl: Photo3,
      },
      {
        url: Photo4,
        thumbUrl: Photo4,
      },
      {
        url: Photo5,
        thumbUrl: Photo5,
      },
      {
        url: Photo6,
        thumbUrl: Photo6,
      },
      {
        url: Photo7,
        thumbUrl: Photo7,
      },
      {
        url: Photo8,
        thumbUrl: Photo8,
      },
      {
        url: Photo9,
        thumbUrl: Photo9,
      },
      {
        url: Photo10,
        thumbUrl: Photo10,
      },
      {
        url: Photo11,
        thumbUrl: Photo11,
      },
      {
        url: Photo12,
        thumbUrl: Photo12,
      },
      {
        url: Photo13,
        thumbUrl: Photo13,
      },
      {
        url: Photo14,
        thumbUrl: Photo14,
      },
      {
        url: Photo15,
        thumbUrl: Photo15,
      },
      {
        url: Photo16,
        thumbUrl: Photo16,
      },
      {
        url: Photo17,
        thumbUrl: Photo17,
      },
      {
        url: Photo18,
        thumbUrl: Photo18,
      },
      {
        url: Photo19,
        thumbUrl: Photo19,
      },
      {
        url: Photo20,
        thumbUrl: Photo20,
      },
      {
        url: Photo21,
        thumbUrl: Photo21,
      },
      {
        url: Photo22,
        thumbUrl: Photo22,
      },
      {
        url: Photo23,
        thumbUrl: Photo23,
      },
      {
        url: Photo24,
        thumbUrl: Photo24,
      },
      {
        url: Photo25,
        thumbUrl: Photo25,
      },

      {
        url: Photo26,
        thumbUrl: Photo26,
      },

      {
        url: Photo27,
        thumbUrl: Photo27,
      },
      {
        url: Photo28,
        thumbUrl: Photo28,
      },
    ];

    return (
      <div
        style={{
          marginBottom: "150px",
          marginTop: "150px",
          border: "1.5px dotted black",
        }}
      >
        <DztImageGalleryComponent images={data} />
      </div>
    );
  }
}
export default Gallery;
