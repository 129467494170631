import React from "react";

import "./Form.styles.css";
import { Button } from "reactstrap";
import Reaptcha from "reaptcha";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.state = {
      nom: "",
      prénom: "",
      société: "",
      téléphone: "",
      email: "",
      message: "",
      verified: false
    };
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true
    });
  };

  handleSubmit(e) {
    const data = JSON.stringify({
      nom: this.state.nom,
      prénom: this.state.prénom,
      société: this.state.société,
      téléphone: this.state.téléphone,
      email: this.state.email,
      message: this.state.message
    });

    fetch("/backend.php", {
      method: "POST",
      body: data
    }).then(response => {
      if (response.ok) {
        this.setState({
          nom: " ",
          prénom: " ",
          société: " ",
          téléphone: " ",
          email: " ",
          message: " "
        });
      }
    });
  }

  render() {
    return (
      <div className="footerMargin">
        <form className="Form" onSubmit={this.handleSubmit}>
          <label>Contact</label>
          <input
            onChange={e => this.setState({ nom: e.target.value })}
            required
            type="search"
            className="input"
            placeholder="Nom"
          />
          <input
            onChange={e => this.setState({ prénom: e.target.value })}
            type="search"
            className="input"
            placeholder="Prénom"
          />
          <input
            onChange={e => this.setState({ société: e.target.value })}
            type="search"
            className="input"
            placeholder="Société"
          />
          <input
            onChange={e => this.setState({ téléphone: e.target.value })}
            required
            type="number"
            className="input"
            placeholder="Téléphone"
          />
          <input
            onChange={e => this.setState({ email: e.target.value })}
            type="email"
            className="mail input"
            placeholder="Email"
          />
          <textarea
            placeholder="Message"
            required
            type="textarea"
            className="input textarea"
            onChange={e => this.setState({ message: e.target.value })}
          />

          <Reaptcha
            sitekey="6LcpTOMUAAAAAJlD32rd1Qd2pKQKGMqevFcoCtZD"
            onVerify={this.onVerify}
            theme="dark"
          />
          <Button
            style={{ margin: "10px" }}
            type="submit"
            disabled={!this.state.verified}
          >
            Envoyer
          </Button>
        </form>
      </div>
    );
  }
}

export default Form;
